/**
 * Create by zhuzhaoqing
 * Description: permission
 * Date: 2023/3/31 16:05
 **/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 获取角色列表
export const getRoleList = (data: any) => request('/payAdminServer/rolemgnt/getRoleInfoByPage2', data, 'GET', null)

//权限数据
export const getAllPerms = () => request('/payAdminServer/permmgnt/getAllPerms', null, 'GET', null)

//修改角色
export const editRolePerms = (data: any) => request('/payAdminServer/roleperm/addRolePerms', data, 'POST', null)

// 修改用户权限
export const editUserPerms = (data: any) => request('/payAdminServer/userperm/addUserPerms', null, 'POST', data)

// 查询应用下所有用户及其对应的角色信息
export const queryAllUsers = (data: any) => request('/payAdminServer/usermgnt/getUserInfoByPage', data, 'GET')

// 查询应用下所有用户及其对应的应用列表
export const queryAllApps = (data: any) => request('/payAdminServer/userDbt/selectDbtByUserId', data, 'GET')

// 根据userId添加应用权限
export const saveDbtByUserId = (data: any) => request('/payAdminServer/userDbt/saveDbtByUserId', null, 'POST_JSON',data)
