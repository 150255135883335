import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2fa987c8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "footer-wrap m-t-24"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_transfer = _resolveComponent("a-transfer");
  const _component_a_button = _resolveComponent("a-button");
  const _component_a_modal = _resolveComponent("a-modal");
  return _openBlock(), _createBlock(_component_a_modal, {
    centered: "",
    title: "权限管理",
    width: "750px",
    open: _ctx.visible,
    "onUpdate:open": _cache[1] || (_cache[1] = $event => _ctx.visible = $event),
    onCancel: _ctx.closeModal,
    footer: false
  }, {
    default: _withCtx(() => [(_openBlock(), _createBlock(_component_a_transfer, {
      "show-search": "",
      listStyle: _ctx.listStyle,
      "target-keys": _ctx.targetKeys,
      "onUpdate:target-keys": _cache[0] || (_cache[0] = $event => _ctx.targetKeys = $event),
      "data-source": _ctx.mockData,
      "row-key": record => {
        var _record$id;
        return (_record$id = record.id) === null || _record$id === void 0 ? void 0 : _record$id.toString();
      },
      "filter-option": _ctx.filterOption,
      titles: ['未授权', '已授权'],
      key: _ctx.randomKey,
      render: item => item.name
    }, null, 8, ["listStyle", "target-keys", "data-source", "row-key", "filter-option", "render"])), _createElementVNode("div", _hoisted_1, [_createVNode(_component_a_button, {
      onClick: _ctx.closeModal
    }, {
      default: _withCtx(() => [_createTextVNode("取消")]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_a_button, {
      type: "primary",
      loading: _ctx.loading,
      onClick: _ctx.savePermissionId
    }, {
      default: _withCtx(() => [_createTextVNode("保存")]),
      _: 1
    }, 8, ["loading", "onClick"])])]),
    _: 1
  }, 8, ["open", "onCancel"]);
}