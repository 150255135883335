import { defineComponent, ref, watch } from 'vue';
import { editRolePerms, editUserPerms, saveDbtByUserId } from '@/api/permission';
import { message } from 'ant-design-vue';
import { reportEvent } from '@/common';
export default defineComponent({
  name: 'RightModal',
  props: {
    modelVisible: {
      default: false,
      type: Boolean
    },
    permList: {
      default: () => [],
      type: Array
    },
    editItem: {
      default: () => {},
      type: Object
    },
    type: {
      default: 'user',
      type: String
    }
  },
  setup(props, {
    emit
  }) {
    const visible = ref(false),
      loading = ref(false),
      targetKeys = ref([]),
      mockData = ref(),
      listStyle = ref({
        width: '50%',
        minHeight: '500px'
      }),
      randomKey = ref(0);
    const closeModal = () => {
        targetKeys.value = [];
        randomKey.value = Math.random();
        emit('update:modelVisible', false);
        emit('update:editItem', {});
      },
      filterOption = (inputValue, option) => {
        return option.name.indexOf(inputValue) > -1;
      },
      savePermissionId = async () => {
        let params = {},
          fn;
        if (props.type === 'user') {
          params = {
            pid: targetKeys.value.join(','),
            uid: props.editItem.id
          };
          fn = editUserPerms;
        } else if (props.type === 'app') {
          var _targetKeys$value, _props$editItem;
          params = {
            "dbtids": (_targetKeys$value = targetKeys.value) === null || _targetKeys$value === void 0 ? void 0 : _targetKeys$value.filter(item => !!item),
            "userid": (_props$editItem = props.editItem) === null || _props$editItem === void 0 ? void 0 : _props$editItem.id
          };
          fn = saveDbtByUserId;
        } else {
          params = {
            pid: targetKeys.value.join(','),
            rid: props.editItem.id
          };
          fn = editRolePerms;
        }
        try {
          let {
            code
          } = await fn(params);
          if (code === '0000' || code === '0') {
            message.success('保存成功');
            emit('updateData');
            closeModal();
            reportEvent({
              eventName: 'element_use',
              properties: {
                'ui_name': '权限管理',
                'element_name': '权限保存'
              }
            });
          }
        } catch (e) {}
      };
    watch(() => props.modelVisible, n => {
      visible.value = n;
    }, {
      immediate: true
    });
    watch(() => props.permList, (n, o) => {
      if (n !== o) {
        mockData.value = n;
      }
    }, {
      immediate: true
    });
    watch(() => props.editItem, (n, o) => {
      if (n !== o) {
        var _n$permId;
        targetKeys.value = (_n$permId = n.permId) === null || _n$permId === void 0 || (_n$permId = _n$permId.split(',')) === null || _n$permId === void 0 ? void 0 : _n$permId.map(item => item.toString());
      }
    }, {
      immediate: true
    });
    return {
      visible,
      loading,
      targetKeys,
      mockData,
      listStyle,
      randomKey,
      closeModal,
      filterOption,
      savePermissionId
    };
  }
});